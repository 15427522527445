import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import gsap from "gsap";
import clsx from "clsx";

import useNavigationOpened from "src/components/hooks/useNavigationOpened";
import { default as HexarLogo } from 'src/assets/Hexar_logo_color1.svg';
import { default as HexarLogoWhite } from 'src/assets/Hexar_logo_white.svg';
import * as styles from "./Navigation.module.scss";
import MenuLink from "./MenuLink";
import { ButtonArrow } from "./ButtonArrow";
import { ProductMenu } from "./ProductMenu";
import MobileMenuButton from "./MobileMenuButton";

const isBrowser = typeof window !== "undefined"

export default function Navigation() {
  const { menuOpened, toggleMenu } = useNavigationOpened();
  const [borderVisible, setBorderVisible] = useState(false);
  let pathname = "";

  if (isBrowser) {
    pathname = window.location.pathname;
  }
  useEffect(() => {
    const animation = gsap.timeline({
      scrollTrigger: {
        trigger: `body`,
        start: "100px top",
        end: "+=0",
        once: false,

        onEnterBack: () => setBorderVisible(false),
        onLeave: () => setBorderVisible(true),
      },
    });

    return () => {
      animation.kill();
    };
  }, []);

  return (
    <div className={styles.navWrapper}>
      <div className={styles.relative}>
        <div
          className={clsx(
            styles.NavigationContent,
            borderVisible && styles.VisibleBorder, !pathname.includes("blog") && styles.HoverEnabled
          )}
        >
          <div>
            <Link to="/" className={styles.ImageLink}>
              {pathname ? (pathname.includes("blog") && !borderVisible ? <HexarLogoWhite /> : <HexarLogo />) : null
              }
            </Link>
          </div>
          <MobileMenuButton
            opened={menuOpened}
            onClick={() => toggleMenu(!menuOpened)}
            isPathBlog={pathname && pathname.includes("blog") ? true : false}
          />
          <div className={clsx(styles.List, menuOpened && styles.MenuOpened)} >
            <MenuLink borderVisible={borderVisible} to="#" dropdownContent={<ProductMenu />}>
              Produkt <ButtonArrow />
            </MenuLink>
            <MenuLink to="/use-cases" borderVisible={borderVisible} >
              Zastosowanie
            </MenuLink>
            <MenuLink to="/blog" borderVisible={borderVisible} >Blog</MenuLink  >
            <MenuLink to="/contact" borderVisible={borderVisible} >
              Kontakt
            </MenuLink>
          </div>
        </div>
      </div>
    </div>
  );
}
