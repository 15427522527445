import React from "react";

export default function CalendarIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.375 21.6477V10.7217H26.625V21.7353C26.625 26.2403 23.7436 29.0102 19.1332 29.0102H7.85236C3.28526 29.0102 0.375 26.1829 0.375 21.6477ZM7.60748 18.1172C6.94475 18.1473 6.39727 17.6292 6.36846 16.9705C6.36846 16.3103 6.88712 15.7649 7.54985 15.7348C8.19818 15.7348 8.73124 16.2386 8.74565 16.8829C8.77447 17.5446 8.25581 18.0899 7.60748 18.1172ZM13.5287 18.1172C12.866 18.1473 12.3185 17.6292 12.2897 16.9705C12.2897 16.3103 12.8084 15.7649 13.4711 15.7348C14.1194 15.7348 14.6525 16.2386 14.6669 16.8829C14.6957 17.5446 14.177 18.0899 13.5287 18.1172ZM19.407 23.3987C18.7443 23.3843 18.2112 22.839 18.2112 22.1788C18.1968 21.5186 18.7299 20.9747 19.3926 20.9603H19.407C20.0842 20.9603 20.6317 21.5057 20.6317 22.1788C20.6317 22.8533 20.0842 23.3987 19.407 23.3987ZM12.2897 22.1788C12.3185 22.839 12.866 23.3571 13.5287 23.3269C14.177 23.2997 14.6957 22.7543 14.6669 22.0941C14.6525 21.4483 14.1194 20.9445 13.4711 20.9445C12.8084 20.9747 12.2897 21.5186 12.2897 22.1788ZM6.3541 22.1788C6.38292 22.839 6.93039 23.3571 7.59312 23.3269C8.24145 23.2997 8.76011 22.7543 8.7313 22.0941C8.71689 21.4483 8.18382 20.9445 7.5355 20.9445C6.87276 20.9747 6.3541 21.5186 6.3541 22.1788ZM18.2255 16.9561C18.2255 16.2959 18.7442 15.7649 19.4069 15.7506C20.0552 15.7506 20.5739 16.2658 20.6027 16.8987C20.6171 17.5589 20.0984 18.1043 19.4501 18.1172C18.7874 18.1315 18.2399 17.6292 18.2255 16.9705V16.9561Z"
        fill="#F6CE48"
      />
      <path
        opacity="0.4"
        d="M0.379883 10.7215C0.398612 9.87908 0.470648 8.20709 0.606077 7.6689C1.29762 4.64209 3.64601 2.71894 7.00289 2.44482H19.9982C23.3263 2.7333 25.7035 4.66936 26.395 7.6689C26.529 8.19274 26.6011 9.87765 26.6198 10.7215H0.379883Z"
        fill="#F6CE48"
      />
      <path
        d="M8.11131 6.89414C8.74523 6.89414 9.22067 6.42196 9.22067 5.78905V1.41317C9.22067 0.780252 8.74523 0.306641 8.11131 0.306641C7.47739 0.306641 7.00195 0.780252 7.00195 1.41317V5.78905C7.00195 6.42196 7.47739 6.89414 8.11131 6.89414Z"
        fill="#F6CE48"
      />
      <path
        d="M18.8882 6.89414C19.5077 6.89414 19.9975 6.42196 19.9975 5.78905V1.41317C19.9975 0.780252 19.5077 0.306641 18.8882 0.306641C18.2542 0.306641 17.7788 0.780252 17.7788 1.41317V5.78905C17.7788 6.42196 18.2542 6.89414 18.8882 6.89414Z"
        fill="#F6CE48"
      />
    </svg>
  );
}
