import clsx from "clsx";
import React from "react";
import * as styles from "./MobileMenuButton.module.scss";

export default function MobileMenuButton({ onClick, opened, isPathBlog }) {
  return (
    <button
      className={opened ? styles.ButtonOpened : styles.Button}
      onClick={onClick}
    >
      <div className={isPathBlog && styles.White}></div>
    </button>
  );
}
