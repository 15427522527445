import React from "react";

const isBrowser = typeof window !== "undefined"



export const ButtonArrow = () => {
  let pathname = "";

  if (isBrowser) { pathname = window.location.pathname; }

  return (
    <svg
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill={pathname ? (pathname.includes("blog") ? "white" : "black") : "black"}
      xmlns="http://www.w3.org/2000/svg"
      className="menu-item-arrow"
      style={{
        transition: "transform 0.6s ease",
        marginLeft: "12px",
      }}
    >
      <path
        d="M1.24996 0.374987C1.41547 0.374663 1.57586 0.432303 1.7033 0.537903L5.49996 3.71124L9.30371 0.651237C9.37617 0.592398 9.45954 0.548459 9.54903 0.521945C9.63852 0.495431 9.73237 0.486865 9.82518 0.496738C9.91799 0.506611 10.0079 0.53473 10.0898 0.579478C10.1718 0.624227 10.244 0.684721 10.3025 0.757486C10.3613 0.82994 10.4052 0.913308 10.4318 1.0028C10.4583 1.09229 10.4668 1.18614 10.457 1.27895C10.4471 1.37176 10.419 1.46171 10.3742 1.54362C10.3295 1.62552 10.269 1.69778 10.1962 1.75624L5.94621 5.17749C5.81947 5.28167 5.66049 5.33862 5.49642 5.33862C5.33235 5.33862 5.17337 5.28167 5.04663 5.17749L0.79663 1.63582C0.724905 1.57636 0.665616 1.50333 0.622158 1.42091C0.5787 1.3385 0.551928 1.24832 0.543374 1.15555C0.53482 1.06277 0.544654 0.969223 0.57231 0.880254C0.599967 0.791285 0.644903 0.708645 0.704546 0.63707C0.770459 0.555815 0.853555 0.49017 0.94786 0.444855C1.04216 0.399539 1.14534 0.375679 1.24996 0.374987Z"
        fill="current"
      />
    </svg>
  )
};
