import React from "react";
import { Link } from "gatsby";

import CalendarIcon from "src/components/common/Icons/CalendarIcon";
import OnboardingIcon from "src/components/common/Icons/OnboardingIcon";
import PeopleIcon from "src/components/common/Icons/PeopleIcon";
import PieChartIcon from "src/components/common/Icons/PieChartIcon";

import * as styles from "./ProductMenu.module.scss";

export const ProductMenu = () => {
  return (
    <div className={styles.MenuWrapper}>
      <Link to="/product/onboarding-offboarding" className={styles.MenuItem}>
        <div className={styles.Icon}>
          <OnboardingIcon />
        </div>
        <div>
          <h6>Onboarding & Offboarding</h6>
          <span>
            Wdrażaj pracowników w strukturę firmy i kulturę organizacyjną jeszcze zanim pojawią się na stanowisku.
          </span>
        </div>
      </Link>
      <Link to="/product/communication" className={styles.MenuItem}>
        <div className={styles.Icon}>
          <PeopleIcon />
        </div>
        <div>
          <h6>Platforma komunikacyjna</h6>
          <span>
            Przekazuj pracownikom codzienne informacje szybko i bezpośrednio i pozwól im na to samo w drugą stronę!
          </span>
        </div>
      </Link>
      <Link to="/product/organizer" className={styles.MenuItem}>
        <div className={styles.Icon}>
          <CalendarIcon />
        </div>
        <div>
          <h6>Firmowy organizer</h6>
          <span>
            Wysyłaj polecenia do rozproszonych biur i pracowników. Otrzymuj powiadomienia, gdy zostaną wykonane.
          </span>
        </div>
      </Link>
      <Link to="/product/employer-branding" className={styles.MenuItem}>
        <div className={styles.Icon}>
          <PieChartIcon />
        </div>
        <div>
          <h6>Employer Branding</h6>
          <span>
            Wyróżnij się w branży nowoczesnym podejściem do pracowników i komunikacji firmowej.
          </span>
        </div>
      </Link>
    </div>
  );
};
