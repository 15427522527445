import React from "react";
import { Link } from "gatsby";
import useWindowSize from "src/components/hooks/useWindowSize";
import * as styles from "./MenuLink.module.scss";

const isBrowser = typeof window !== "undefined"


export default function MenuLink({ to = "", children = "", dropdownContent, borderVisible }) {
  let pathname = "";
  if (isBrowser) {
    pathname = window.location.pathname;
  }
  const { width } = useWindowSize();

  return (
    <div className={styles.ListButton} >
      <Link className={styles.Link} to={to} style={pathname ? (pathname.includes("blog") && !borderVisible && width >= 1043 ? { color: "white" } : { color: "#222222" }) : { color: "#222222" }}>
        {children}
      </Link>
      {dropdownContent && (
        <div className={styles.DropdownContent}>{dropdownContent}</div>
      )}
    </div>
  );
}
