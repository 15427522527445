import React from "react";

import Navigation from "./navigation/Navigation";
import "./Layout.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrivacyPolicyInfo from "../PrivacyPolicyInfo/PrivacyPolicyInfo";

export default function layout({ children }) {
  return (
    <>
      <Navigation />
      {children}
      <PrivacyPolicyInfo />
    </>
  );
}
