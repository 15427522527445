import React from "react";

export default function PeopleIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 33 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4258 15.3037C11.3946 15.3037 7.14941 16.1128 7.14941 19.2354C7.14941 22.3593 11.422 23.1399 16.4258 23.1399C21.457 23.1399 25.7022 22.3308 25.7022 19.2082C25.7022 16.0844 21.4296 15.3037 16.4258 15.3037Z"
        fill="#287ABE"

      />
      <path
        opacity="0.4"
        d="M16.4257 12.3288C19.8325 12.3288 22.5642 9.62492 22.5642 6.25288C22.5642 2.87955 19.8325 0.177002 16.4257 0.177002C13.0188 0.177002 10.2871 2.87955 10.2871 6.25288C10.2871 9.62492 13.0188 12.3288 16.4257 12.3288Z"
        fill="#287ABE"

      />
      <path
        opacity="0.4"
        d="M29.7537 7.66746C30.6351 4.25535 28.0509 1.19092 24.7603 1.19092C24.4025 1.19092 24.0604 1.22969 23.7261 1.29561C23.6817 1.30595 23.6321 1.32792 23.606 1.36669C23.5759 1.41581 23.5981 1.48172 23.6308 1.52437C24.6193 2.89697 25.1873 4.56813 25.1873 6.36207C25.1873 8.08105 24.6663 9.6837 23.7522 11.0136C23.6582 11.1507 23.7418 11.3355 23.9076 11.3639C24.1374 11.404 24.3725 11.4247 24.6127 11.4311C27.0089 11.4932 29.1595 9.96675 29.7537 7.66746Z"
        fill="#287ABE"

      />
      <path
        d="M32.2637 15.7012C31.825 14.7758 30.766 14.1412 29.1559 13.8298C28.396 13.6462 26.3393 13.3877 24.4263 13.4226C24.3976 13.4265 24.3819 13.4459 24.3793 13.4588C24.3754 13.4769 24.3832 13.5079 24.4211 13.5273C25.3051 13.9603 28.7224 15.8434 28.2928 19.8152C28.2745 19.9871 28.4142 20.1357 28.5879 20.1098C29.4288 19.9909 31.5925 19.5308 32.2637 18.0975C32.6346 17.3401 32.6346 16.4599 32.2637 15.7012Z"
        fill="#287ABE"

      />
      <path
        opacity="0.4"
        d="M9.27374 1.2961C8.94077 1.22889 8.59734 1.19141 8.23955 1.19141C4.94895 1.19141 2.36478 4.25584 3.2475 7.66795C3.84033 9.96724 5.99097 11.4936 8.38711 11.4316C8.62737 11.4251 8.86372 11.4032 9.09224 11.3644C9.25807 11.336 9.34164 11.1511 9.24763 11.0141C8.33357 9.6829 7.81256 8.08153 7.81256 6.36256C7.81256 4.56732 8.38189 2.89617 9.37037 1.52486C9.40171 1.48221 9.42522 1.4163 9.39388 1.36718C9.36776 1.32712 9.31945 1.30644 9.27374 1.2961Z"
        fill="#287ABE"

      />
      <path
        d="M3.8441 13.8294C2.23405 14.1409 1.17636 14.7755 0.73761 15.7009C0.365458 16.4596 0.365458 17.3398 0.73761 18.0984C1.40879 19.5305 3.57249 19.9919 4.41342 20.1095C4.5871 20.1354 4.72551 19.988 4.70723 19.8148C4.27762 15.8444 7.69489 13.9613 8.58022 13.5283C8.61678 13.5076 8.62462 13.4779 8.6207 13.4585C8.61809 13.4456 8.60372 13.4262 8.575 13.4236C6.6607 13.3874 4.60538 13.6459 3.8441 13.8294Z"
        fill="#287ABE"

      />
    </svg>
  );
}
