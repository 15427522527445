// extracted by mini-css-extract-plugin
export var MaxWidthContainer = "Navigation-module--MaxWidthContainer--KNQ8i";
export var relative = "Navigation-module--relative--g4qG-";
export var CommonPaddings = "Navigation-module--CommonPaddings--cgXnk";
export var navWrapper = "Navigation-module--navWrapper--chMYA";
export var NavigationContent = "Navigation-module--NavigationContent--qGefU";
export var HoverEnabled = "Navigation-module--HoverEnabled--lrImW";
export var VisibleBorder = "Navigation-module--VisibleBorder--Bk-Tl";
export var List = "Navigation-module--List--LVTvt";
export var ImageLink = "Navigation-module--ImageLink--KH6px";
export var MenuOpened = "Navigation-module--MenuOpened--NFPaL";
export var item = "Navigation-module--item--wclJb";
export var text = "Navigation-module--text--ukIda";