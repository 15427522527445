import * as React from "react";
import * as styles from './Patrons.module.scss';
import { StaticImage } from "gatsby-plugin-image";

const Patrons = () => {
    return (
        <div className={styles.Patrons}>
            <div className={styles.ImgWrapper}>
                <StaticImage src='../../../../static/Frame3720.png' alt="patrons" className={styles.GatsbyImageWrapper} />
            </div>
            <div className={styles.Copyright}>© Copyright 2021. Wszystkie prawa zastrzeżone.</div>
        </div>
    );
};

export default Patrons;
