import * as React from "react";
import { Helmet } from "react-helmet";

function Seo() {
  return (
    <Helmet >
      <html lang="pl" title="Hexar" />
      <title>Hexar</title>
      <meta name="description" content="Hexar to platforma mobilna, która pozwala skrócić dystans między biurem a rozproszoną załogą, przyspieszając komunikację wewnątrz firmy." />
      <meta name="keywords" content="Komunikacja, Onboarding, Zlecanie zadań, Employer Branding, Employee Experience, Offboarding, HR Tech, Intranet, Mobilny intranet" />
      <meta name="author" content="Hexar" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Space+Grotesk:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-BE0G1LDTHL"></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'G-BE0G1LDTHL');`}
      </script>
      <script>
        {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2647215,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </script>
    </Helmet>
  );
}

export default Seo;
