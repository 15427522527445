import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";

const useNavigationOpened = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (menuOpened && windowSize.width < 1043 && document) {
      document.querySelector("body").classList.add("disable-scroll");
    }

    if (!menuOpened || windowSize.width >= 1043 && document) {
      document.querySelector("body").classList.remove("disable-scroll");
    }
  }, [menuOpened, windowSize]);

  function toggleMenu(value = !menuOpened) {
    setMenuOpened(value);
  }

  return { menuOpened, toggleMenu };
};

export default useNavigationOpened;
