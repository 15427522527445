import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"


import * as styles from "./PrivacyPolicyInfo.module.scss"
import { Link } from "gatsby"
import gsap from "gsap"
import clsx from "clsx"

const PrivacyCookieAcceptKey = "POLICY_ACCEPTED"

export default function PrivacyPolicyInfo() {
    const [isAccepted, setIsAccepted] = useState(
        Cookies.get(PrivacyCookieAcceptKey)
    )

    function handleAcceptPolicy() {
        Cookies.set(PrivacyCookieAcceptKey, "true", { expires: 7 })
        setIsAccepted(true)
    }

    useEffect(() => {
        gsap.fromTo(
            ".animated-privacy-info",
            {
                opacity: 1,
                translateY: "100%",
            },
            {
                transitionDelay: 2,
                transitionDuration: 0.5,
                translateY: "0%",
            }
        )
        return () => { }
    }, [])

    if (isAccepted) {
        return null
    }

    return (
        <div className={clsx(styles.PolicyWrapper, "animated-privacy-info")}>
            <div style={{ marginRight: "8px" }}>
                Używamy plików cookie, aby zapewnić Ci najlepszą jakość korzystania z naszej strony internetowej. Kontynuując korzystanie z tej strony, akceptujesz naszą{" "}
                <Link style={{ color: "black" }} to="/privacy-policy">
                    Politykę Prywatności
                </Link>
                .
            </div>
            <div
                variant="body2"
                onClick={handleAcceptPolicy}
                style={{ color: "rgba(70, 79, 252, 1)", cursor: "pointer" }}
            >
                Rozumiem!
            </div>
        </div>
    )
}