import React from "react";

export default function PieChartIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8059 5.40942C12.8802 5.5582 12.9293 5.71753 12.9512 5.88133L13.3573 11.8229L13.5588 14.8092C13.5609 15.1163 13.6099 15.4215 13.7041 15.7148C13.9476 16.2839 14.5332 16.6455 15.1703 16.6203L24.8789 15.9954C25.2993 15.9886 25.7053 16.1433 26.0075 16.4255C26.2593 16.6608 26.4219 16.9685 26.4732 17.2994L26.4904 17.5004C26.0886 22.9752 22.0028 27.5416 16.4512 28.7204C10.8996 29.8991 5.20672 27.409 2.46337 22.602C1.67249 21.2055 1.17849 19.6705 1.01039 18.0871C0.940171 17.6183 0.909249 17.1449 0.917932 16.6713C0.909262 10.8017 5.15656 5.72722 11.102 4.50388C11.8176 4.39423 12.519 4.76703 12.8059 5.40942Z"
        fill="#75529D" />
      <path
        opacity="0.4"
        d="M16.7685 0.307816C23.4183 0.474308 29.0073 5.18023 30.0831 11.5187L30.0728 11.5655L30.0435 11.6335L30.0475 11.8202C30.0323 12.0676 29.9353 12.3056 29.768 12.4978C29.5938 12.698 29.3558 12.8344 29.0937 12.8873L28.9339 12.9089L17.7328 13.6232C17.3602 13.6593 16.9892 13.5411 16.7121 13.2979C16.4812 13.0952 16.3336 12.8216 16.2919 12.5268L15.5401 1.51946C15.527 1.48224 15.527 1.44189 15.5401 1.40467C15.5504 1.10126 15.6861 0.814466 15.9169 0.608359C16.1477 0.402252 16.4544 0.29401 16.7685 0.307816Z"
        fill="#75529D"
      />
    </svg>
  );
}
