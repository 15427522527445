import React from "react";

export default function OnboardingIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M7.64014 6.68748C7.64014 3.16984 10.644 0.306641 14.336 0.306641H21.6748C25.3577 0.306641 28.354 3.16266 28.354 6.67312V22.6266C28.354 26.1457 25.3517 29.0104 21.6597 29.0104H14.3209C10.638 29.0104 7.64014 26.1529 7.64014 22.641V21.2919V6.68748Z"
        fill="#EA5045"
      />
      <path
        d="M20.3875 13.8745L16.0593 9.69951C15.612 9.26896 14.8921 9.26896 14.4463 9.70238C14.0019 10.1358 14.0034 10.8347 14.4492 11.2653L16.8192 13.5515H1.78607C1.15654 13.5515 0.645508 14.0467 0.645508 14.6581C0.645508 15.268 1.15654 15.7617 1.78607 15.7617H16.8192L14.4492 18.0494C14.0034 18.48 14.0019 19.1789 14.4463 19.6123C14.6699 19.829 14.9617 19.9381 15.255 19.9381C15.5454 19.9381 15.8372 19.829 16.0593 19.6152L20.3875 15.4403C20.6023 15.2322 20.7238 14.9509 20.7238 14.6581C20.7238 14.3639 20.6023 14.0826 20.3875 13.8745Z"
        fill="#EA5045"
      />
    </svg>
  );
}
